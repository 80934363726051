var subtitles__3 = [

	{ "start": "0.5", "end": "2.0", "text": "The Corridor speaks" },

	{ "start": "2.7", "end": "4.7", "text": "as a carrier of stories" },
	
	{ "start": "4.9", "end": "6.8", "text": "with doors passing me by," }, 
	
	{ "start": "7.9", "end": "11.2", "text": "conduits for both bodies and information," },
	
	{ "start": "11.6", "end": "13.3", "text": "delivered by a courier," }, 
	
	{ "start": "13.8", "end": "15.9", "text": "someone who could run fast." },
	
	{ "start": "17.8", "end": "20.3", "text": "Physical transit mechanisms" }, 
	
	{ "start": "20.9", "end": "24.8", "text": "that seem to blend in to so many other spaces." },
	
	{ "start": "26.4", "end": "29.4", "text": "A medial space par excellence" },
	
	{ "start": "30.0", "end": "33.6", "text": "with mirrors as metaphors for hierarchies." },
	
	{ "start": "34.9", "end": "36.6", "text": "Can blue solve the problem?" },
	
	{ "start": "37.6", "end": "43.0", "text": "In the Middle Ages, for example, blue was a warm color." },
	
	{ "start": "44.8", "end": "50.8", "text": "At the time when Hildegard von Bingen invented her Lingua Ignota," }, 
	
	{ "start": "51.69", "end": "57.3", "text": "a thousand unknown words to increase solidarity among her nuns." },
	
	{ "start": "58.3", "end": "61.0", "text": "There was no “I” in her invention" },
	
	{ "start": "61.4", "end": "63.2", "text": "and no word for “word”." },
	
	{ "start": "64.3", "end": "76.3", "text": "In this sense architecture may be context, but it is also text — it reveals, structures and formalizes relationships." }, 
	
	{ "start": "78.4", "end": "80.3", "text": "Institutional Matterings" },
	
	{ "start": "80.6", "end": "82.4", "text": "Topological journeys" },
	
	{ "start": "83.2", "end": "85.0", "text": "Cross-identifications" }, 
	
	{ "start": "85.6", "end": "88.0", "text": "of interiors and interiority" },
	
	{ "start": "88.9", "end": "91.2", "text": "through bureaucratic activities," },
	
	{ "start": "91.8", "end": "94.9", "text": "within bureaucratic architectures." }, 
	
	{ "start": "97.5", "end": "103.1", "text": "She also invented a “Geheimschrift” and wrote on a wax tablet." },
	
	{ "start": "103.8", "end": "106.7", "text": "Fonts created for foreign characters" },
	
	{ "start": "108.0", "end": "116.9", "text": "Her letter “o” looks like a heart shape or an elaborate squiggle, her “b” looks like a running figure." },
	
	{ "start": "118.8", "end": "121.1", "text": "Walk together in the cloister," },
	
	{ "start": "121.9", "end": "125.4", "text": "Christel says: as slow as possible." },
	
	{ "start": "127.7", "end": "140.8", "text": "Coming from the street into the cloister, then into the courtyard and back into the cloister again and out behind the glass pane onto Neue Straße." },
	
	{ "start": "142.5", "end": "144.3", "text": "Threading and unthreading" },
	
	{ "start": "145.4", "end": "146.8", "text": "Laying cables" },
	
	{ "start": "148.0", "end": "156.1", "text": "Since the electronic light and the telephone were invented people have been fixing cables under the ceiling." }, 
	
	{ "start": "157.6", "end": "160.2", "text": "Usually under a suspended ceiling." },
	
	{ "start": "162.2", "end": "164.2", "text": "Architectures for sparks," },
	
	{ "start": "165.8", "end": "167.3", "text": "fast as light" },
	
	{ "start": "169.0", "end": "170.5", "text": "Glosso-poeia" },
	
	{ "start": "171.7", "end": "173.7", "text": "Glosso-poetics" },
	
	{ "start": "175.3", "end": "177.2", "text": "Glosso-graphia" },
	
	{ "start": "178.9", "end": "183.2", "text": "glōssa: tongue, language" },
	
	{ "start": "184.5", "end": "186.3", "text": "speaking in tongues" },
	
	{ "start": "188.2", "end": "190.4", "text": "mystical paroxysms" },
	
	{ "start": "191.7", "end": "193.2", "text": "automatic writing" },
	
	{ "start": "194.1", "end": "196.6", "text": "linguistic distortion" },
	
	{ "start": "198.6", "end": "200.8", "text": "delicious speculation" },
	
	{ "start": "201.4", "end": "203.0", "text": "or dangerous gossip" },
	
	{ "start": "204.4", "end": "209.4", "text": "When a woman invents language, antennas are raised." },
	
	{ "start": "211.7", "end": "214.3", "text": "Institutional Matterings" },
	
	{ "start": "216.2", "end": "225.3", "text": "We painted the advertising column in front of the Kunsthalle: It was purple-blue, now it is yellow." },
	
	{ "start": "226.5", "end": "230.5", "text": "We started to learn a new language: Simple Language" },
	
	{ "start": "232.3", "end": "235.3", "text": "We started to gender with a colon." },
	
	{ "start": "236.4", "end": "240.3", "text": "The city of Osnabrück didn’t gender that much before." },
	
	{ "start": "243.0", "end": "250.6", "text": "Around the year 1400, Christine de Pizan invented an ideal “City of Ladies”." },
	
	{ "start": "252.1", "end": "258.5", "text": "A City for women, built by women, and constructed by stories of women," },
	
	{ "start": "259.3", "end": "260.4", "text": "but not only women," },
	
	{ "start": "261.9", "end": "269.9", "text": "in medieval legends are narratives of female travesties, of survival in men's clothing." },
	
	{ "start": "271.8", "end": "275.7", "text": "Christine de Pizan was very familiar with this tradition" },
	
	{ "start": "276.5", "end": "280.5", "text": "and that is reminding us that Hildegard herself," }, 
	
	{ "start": "281.3", "end": "285.4", "text": "with her frequent use of cross-sexual imagery" },
	
	{ "start": "286.1", "end": "293.0", "text": "instinctively avoiding to associate the feminine exclusively with women." },
	
	{ "start": "295.1", "end": "296.9", "text": "Can blue solve the problem?" },
	
	{ "start": "298.4", "end": "302.0", "text": "Within the walls of this allegorical city" },
	
	{ "start": "302.8", "end": "306.3", "text": "and her own implausibility as a vessel," }, 
	
	{ "start": "307.5", "end": "311.8", "text": "color could be more in the nature of matter than of light." }, 
	
	{ "start": "313.4", "end": "317.0", "text": "As an envelope, a mask, a vanitas" },
	
	{ "start": "318.8", "end": "319.4", "text": "color" },
	
	{ "start": "321.0", "end": "325.5", "text": "celare: to conceal, to hide, to deceive" },
	
	{ "start": "327.2", "end": "331.9", "text": "In the 13th century the interest in blue tones awoke," },
	
	{ "start": "332.9", "end": "334.8", "text": "the blue of lapis lazuli," },
	
	{ "start": "336.7", "end": "339.3", "text": "for a new theology of light:" },
	
	{ "start": "340.3", "end": "343.0", "text": "Blue stained-glass windows," }, 
	
	{ "start": "343.9", "end": "347.4", "text": "while Mary was not always dressed in blue." }, 
	
	{ "start": "349.1", "end": "353.8", "text": "And the femininity of wisdom was largely suppressed," },  
	
	{ "start": "354.6", "end": "358.3", "text": "she nonetheless returned in the guise of fabula." }, 
	
	{ "start": "360.1", "end": "362.8", "text": "Sophia, Sapientia" }, 
	
	{ "start": "363.5", "end": "366.6", "text": "with a queer-feminist building code." }, 
	
	{ "start": "367.8", "end": "375.4", "text": "And there are 2 abbesses who have equal rights to lead the monastery, always have." },
	
	
	

]